exports.components = {
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-agencies-tsx": () => import("./../../../src/pages/agencies.tsx" /* webpackChunkName: "component---src-pages-agencies-tsx" */),
  "component---src-pages-amazon-advertising-software-tsx": () => import("./../../../src/pages/amazon-advertising-software.tsx" /* webpackChunkName: "component---src-pages-amazon-advertising-software-tsx" */),
  "component---src-pages-amazon-dsp-tsx": () => import("./../../../src/pages/amazon-dsp.tsx" /* webpackChunkName: "component---src-pages-amazon-dsp-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-sponsored-brands-video-tsx": () => import("./../../../src/pages/sponsored-brands-video.tsx" /* webpackChunkName: "component---src-pages-sponsored-brands-video-tsx" */),
  "component---src-templates-blog-article-index-tsx": () => import("./../../../src/templates/BlogArticle/index.tsx" /* webpackChunkName: "component---src-templates-blog-article-index-tsx" */),
  "component---src-templates-blog-category-index-tsx": () => import("./../../../src/templates/BlogCategory/index.tsx" /* webpackChunkName: "component---src-templates-blog-category-index-tsx" */)
}

